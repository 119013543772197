/**
 * Main JS
 */
(function($) {

  /**
   * Set the body padding top to account for fixed header
   */
  function set_body_padding () {
    let hh = $('header.header').outerHeight();
    $('body').css('padding-top', hh);
  }//end set_body_padding


  /**
   * Set the height of the heading wrap element for the team member items to maintain the layout
   */
  function set_headings_height() {
    let item = $('div.team-members-carousel div.owl-item div.heading-wrap');
    if (typeof item !== 'undefined') {
      let min_h = 0;
      item.attr('style', '');
      item.each(function () {
        let $this = $(this),
          this_h = parseInt($this.height());

        if (min_h < this_h) {
          min_h = this_h;
        }
      });

      item.height(min_h);
    }
  }//end set_headings_height


  /**
   * Set the nav role to the carousel buttons
   * @param $carousel
   */
  function set_nav_role_attr($carousel) {
    $carousel.find('button[class^="owl-"]').each(function(index) {
      $(this).attr('role', 'button');
    });
  }//end set_nav_role_attr


  /**
   * Set the width and position of a pseudo elem to hide the team carousel overflow.
   */
  function set_overflow_cover_width () {
    let cover = $('section.team-members-section div.overflow-cover');
    if (cover.length) {
      cover.css({'left': -cover.offset().left, 'width': cover.offset().left});
    }
  }//end set_overflow_cover_width


  $(function() {

    let header_search = $('div.mcc-header-search');

    //Set the body padding top on window resize to accommodate fixed header
    set_body_padding();
    $(window).resize(function () {
      set_body_padding();
      set_overflow_cover_width();
    });


    //Mobile nav menu click
    $(document).on('click', 'div.mobile-menu-toggle button', function () {
      let $this = $(this),
          navbar = $('nav.navbar');

      navbar.find('div.navbar-collapse').toggleClass('collapse');

      //Open nav
      if ($this.hasClass('closed')) {
        $this.attr('aria-expanded', 'true').removeClass('closed');
      } else {
        $this.attr('aria-expanded', 'false').addClass('closed');
      }

    });


    //Nav search open click
    $('#mcc-search').click(function(){
      header_search.removeClass('inactive');

      setTimeout(function () {
        header_search.addClass('active');
        $('#header-search-input').focus();
      }, 50);


    });


    //Nav search close click
    $('button.header-search-close').click(function(){
      header_search.removeClass('active');

      setTimeout(function () {
        header_search.addClass('inactive');
        $('#header-search-input').blur();
      }, 650);
    });


    //Team members carousel
    if ($('.team-members-carousel').length) {

      $('.team-members-carousel').owlCarousel({
        items: 3,
        dots: false,
        navContainer: '#owl-nav-container',
        margin: 30,
        navText: ['<img src="/wp-content/themes/MenstrualCupCoalition/assets/img/svg/arrow-left-light-green.svg" alt="">', '<img src="/wp-content/themes/MenstrualCupCoalition/assets/img/svg/arrow-right-light-green.svg" alt="">'],
        onInitialized: function(){
          $('div#owl-nav-container button.owl-prev').addClass('inactive').prop('disabled', true);
          set_headings_height();
          set_nav_role_attr($('#owl-nav-container'));
          set_overflow_cover_width();
        },
        onChanged: function(e){
          if (e.item.index === 0) {
            $('div#owl-nav-container button.owl-prev').addClass('inactive').prop('disabled', true);
            $('div#owl-nav-container button.owl-next').removeClass('inactive').prop('disabled', false);
          } else if (e.relatedTarget.current() === e.relatedTarget.maximum()) {
            $('div#owl-nav-container button.owl-next').addClass('inactive').prop('disabled', true);
            $('div#owl-nav-container button.owl-prev').removeClass('inactive').prop('disabled', false);
          } else {
            $('div#owl-nav-container button').removeClass('inactive').prop('disabled', false);
          }
        },
        onResized:function(){
          set_headings_height();
          set_overflow_cover_width();
        },
        responsiveClass: true,
        responsive: {
          0: {
            margin: 0,
            items: 1,
          },
          767: {
            margin: 30,
            items: 1,
          },
          768: {
            margin: 30,
            items: 2,
          },
          991: {
            margin: 30,
            items: 2,
          },
          992: {
            margin: 30,
            items: 3,
          }
        }
      });//end img carousel

    }//end team members carousel


    //Testimonials carousel
    if ($('.testimonials-carousel').length) {

      let testimonial_carousel = $('.testimonials-carousel');
      testimonial_carousel.each(function(){

        let $this = $(this);
        $this.owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          navText: ['<img src="/wp-content/themes/MenstrualCupCoalition/assets/img/svg/arrow-left-light-green.svg" alt="Light green left arrow icon">', '<img src="/wp-content/themes/MenstrualCupCoalition/assets/img/svg/arrow-right-light-green.svg" alt="Teal right arrow icon">'],
          onInitialized: function(){
            set_nav_role_attr($this);
          }
        });//end img carousel

      });

    }//end if testimonials len


    //Scroll to open / active tab on accordion
    if ($('.mcc-accordion').length) {
      $('.mcc-accordion').each(function () {

        var $this = $(this);
        $this.find('.accordion-collapse').on('shown.bs.collapse', function (e) {
          var $panel = $(this).closest('.accordion-item');
          var header_h = $('header.header').outerHeight();
          var scroll_top = (parseInt($panel.offset().top) - header_h);

          $('html,body').animate({
            scrollTop: scroll_top
          }, 500);
        });

      });
    }//end scroll to active tab in accordion


    //Accordion item link click - link to another accordion item
    $('a.mcc-accordion-link').click(function(e){

      e.preventDefault();
      let id = $(this).attr('href');

      if ($('button.accordion-button.'+id).length) {
        $('button.accordion-button.'+id).trigger('click');
      }

      return false;

    });


    //Contact form submit button click: do not submit if terms checkbox hasn't been checked.
    $('button.mcc-cf7-button.contact-form-page').click(function(e){

      if (!$('input#mcc-contact-agree').prop('checked')) {
        e.preventDefault();
        $('label[for="mcc-contact-agree"]').addClass('error');
        $('input#mcc-contact-agree').focus();
        return false;
      } else {
        $('label[for="mcc-contact-agree"]').removeClass('error');
      }

    });


    //Invest form submit button click: do not submit if terms checkbox hasn't been checked.
    $('button.mcc-cf7-button.mcc-invest-submit').click(function(e){

      if (!$('input#mcc-invest-agree').prop('checked')) {
        e.preventDefault();
        $('label[for="mcc-invest-agree"]').addClass('error');
        $('input#mcc-invest-agree').focus();
        return false;
      } else {
        $('label[for="mcc-invest-agree"]').removeClass('error');
      }

    });


    //Invest modal button click
    $('a.mcc-invest-modal, li.mobile-invest > a').click(function(e){

      e.preventDefault();

      $('button#invest-modal-button').trigger('click');

      return false;

    });


    //Invest modal sent confirmation message:
    document.addEventListener( 'wpcf7mailsent', function( event ) {

      let form_id = event.detail.contactFormId;

      //The invest modal form
      if (form_id === 501) {
        $('div.invest-modal-confirmation').addClass('active');
      }

    }, false );


  });

})(jQuery);
